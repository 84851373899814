import React, { useEffect, useRef, useState } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";

//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Footer from "components/Saas/Footer";
import SideMenu from "components/ITCreative/SideMenu";
import axios from "axios";

const PageSinglePost5 = ({ isWide, leftSidebar, style = "4", rtl, params }) => {
  const navbarRef = useRef(null);
  //const data =  news;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = params;

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios
        .get(`https://blog.mutualtrustmfb.com/posts/${id}`)
        .then((response) => {
          const res = setData(response.data);
          console.log(res);
        });

      setLoading(false);
    })();
  }, []);

  return (
    <MainLayout>
      <SideMenu />
      <Navbar navbarRef={navbarRef} />
      <main className="blog-page style-5 ">
        <section className="all-news section-padding pt-50 blog bg-transparent style-3">
          <div className="container">
            <div className="blog-details-slider mb-100">
             
              <div className="content-card">
                <div className="img">
                  <img src={data.imgUrl} alt="" />
                </div>
                <div className="info">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="cont">
                        <small className="date small mb-20">
                          {" "}
                          <span className="text-uppercase border-end brd-gray pe-3 me-3">
                            {" "}
                            News{" "}
                          </span>{" "}
                          <i className="far fa-clock me-1"></i> Posted on 3 Days
                          ago{" "}
                        </small>
                        <h2 className="title">
                          Solutions For Big Data Issue, Expert Perspective
                        </h2>
                        <p className="fs-12px mt-10 text-light text-info">
                          If there’s one way that wireless technology has
                          changed the way we work, it’s that will everyone is
                          now connected [...]
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-4 gx-lg-5">
             
              <div className={isWide ? "col-lg-12" : "col-lg-8"}>
                <div className="blog-content-info">
                  <h4 className="fw-bold color-000 lh-4 mb-30">
                   { data.title }
                  </h4>
                  <div className="text mb-10 color-666">
                  <div dangerouslySetInnerHTML={{ __html: `${ data.desc }`}} />
                 
                  </div>
                  
                </div>
              </div>
              {!isWide && !leftSidebar && (
                <div className="col-lg-4">
                  <div className="side-blog style-5 ps-lg-5 mt-5 mt-lg-0">
                    <form
                      action="contact.php"
                      className="search-form mb-50"
                      method="post"
                    >
                      <h6 className="title mb-20 text-uppercase fw-normal">
                        {"search"}
                      </h6>
                      <div className="form-group position-relative">
                        <input
                          type="text"
                          className="form-control rounded-pill"
                          placeholder={
                            rtl ? "اكتب وارسل" : "Type and hit enter"
                          }
                        />
                        <button className="search-btn border-0 bg-transparent">
                          {" "}
                          <i className="fas fa-search"></i>{" "}
                        </button>
                      </div>
                    </form>

                    <div className="side-categories mb-50">
                      <h6 className="title mb-20 text-uppercase fw-normal">
                        {"categories"}
                      </h6>
                      {/* {data.categories.map((category, index) => (
                        <a href="#" className="cat-item" key={index}>
                          <span>{category.title}</span>
                          <span>{category.count}</span>
                        </a>
                      ))} */}
                    </div>

                    <div className="side-newsletter mb-50">
                      <h6 className="title mb-10 text-uppercase fw-normal">
                        {"newsletter"}
                      </h6>
                      <div className="text">
                        {rtl
                          ? "سجل الآن للحصول على آخر التحديثات الخاصة بالعروض الترويجية والقسائم."
                          : "Register now to get latest updates on promotions & coupons."}
                      </div>
                      <form
                        action="contact.php"
                        className="form-subscribe"
                        method="post"
                      >
                        <div className="email-input d-flex align-items-center py-3 px-3 bg-white mt-3 radius-5">
                          <span className="icon me-2 flex-shrink-0">
                            <i className="far fa-envelope"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Email Address"
                            className="border-0 bg-transparent fs-12px"
                          />
                        </div>
                        <button
                          className={`btn bg-blue${style} sm-butn text-white hover-darkBlue w-100 mt-3 radius-5 py-3`}
                        >
                          <span>{"Subscribe"}</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer noWave />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>Mutualtrust</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default PageSinglePost5;
